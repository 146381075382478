// ==================================================================
// header

// nav desktop upper
.nav .nav-desktop__upper__content {
	background-color: $primary-color !important;
	border-bottom: 2px solid white;
	max-height: 10.5rem;
	min-height: 10.5rem;
	height: 10.5rem;
}

// nav search
.nav .nav-search .nav-search-bar form .nav-search-bar__text {
	border-color: black;
	color: black;
}

// nav info icon 
.nav-info-icon {
	i, .icon, span, .mdi {
		color: black !important;
	}
}

// nav logo
.nav-logo {
	&--large img {
		height: 11.75rem;
	}

	&--small img {
		height: 4rem;
	}
}

// nav background
.nav-background {
	height: 10rem;

	@include media-breakpoint-down(m) {
		height: 0;
	}
}

// ==================================================================
// footer

.valign-wrapper {
	display: block;

	@include media-breakpoint-up(xl) {
		display: none;
	}
}

.page-footer-floating__logo {
	display: block !important;

	img {
		display: block;
		margin: 0;
		width: 80px;
		height: auto;
	}
}

.page-footer-floating {
	position: fixed;
	display: none;
	background: $primary-color;
	max-height: rem(90px);
	height: rem(90px);
	width: 100%;
	z-index: 3;
	bottom: 0;
	left: 0;

	@include media-breakpoint-up(xl) {
		display: block !important;
	}

	.container {
		height: 100%;
	}

	&__wrapper {
		padding-left: rem(6px);
		padding-right: rem(6px);
		justify-content: space-between;
		align-items: center;
		display: flex;
		height: 100%;
	}

	&__web-channel {
		cursor: pointer;
		display: flex;

		&__icon {
			height: rem(55px);
			width: rem(55px);
			background: $secondary-color;
			justify-content: center;
			align-items: center;
			display: flex;

			i {
				font-size: 2rem;
				color: white;
			}
		}

		&__text {
			text-overflow: ellipsis;
			align-items: center;
			white-space: nowrap;
			overflow: hidden;
			display: flex;
			background: black;
			padding-right: rem(24px);
			height: rem(55px);
			max-width: 490px;

			h4 {
				margin-left: rem(24px);
				font-size: 1.5rem;
				max-width: 440px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				display: block;
				color: white;
			}
		}
	}

	&__team-name {
		&__wrapper {
			text-align: center;

			.team-name__upper {
				h3 {
					color: white;
					// color: black;
					font-size: 1.5rem;
					margin: 0;
				}
			}

			.team-name__lower {
				h3 {
					color: $secondary-color;
					// color: black;
					font-size: 1.5rem;
					margin: 0;
				}
			}
		}
	}

	&__team-img {
		position: relative;
		height: 100%;
		width: 260px;

		img {
			position: absolute;
			height: auto;
			width: 100%;
			bottom: 0;
			left: 0;
		}
	}

	&__logo {
		margin-left: 1rem;

		img {
			height: rem(58px);
			width: auto;
		}
	}
}

// ==================================================================
// traffic tabs

.traffic-tabs .tab a.active {
	color: black;
}

.traffic-tabs .tab a {
	color: black;
	&:hover {
		color: black;
	}
}

// ==================================================================
// floating action buttons

.floating-action-buttons {
	right: auto !important;
	left: 20px !important;

	@include media-breakpoint-down(sm) {
		right: 20px !important;
		left: auto !important;
	
	}
}

.fab__scroll {
	color: black !important;
}

// ==================================================================
// typography 

.category__title {
	color: color("grey", "darken-2");
}

.teaser-link {
	color: color("grey", "darken-2");
}

a {
	font-weight: bold;
	color: color("grey", "darken-2");
}

// Header Styles
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	color: color("grey", "darken-2");
}

nav.breadcrumb a {
	@include changeLinkColor(calc(100% - 25px), $secondary-color);
	font-weight: normal;
}

p > a,
a.trigger-info-window,
a.teaser-link {
	@include changeLinkColor;
}

.playlist__affiliate__icon {
	color: white !important;
}

.share__button__icon {
	color: white !important;
}

.nav-info-icon * {
	font-weight: normal !important;
}

.page-footer {
	a {
		font-weight: normal !important;
	}
}

.breadcrumb:before {
	color: color("grey", "darken-2") !important;
}

// infobox active tab title
.infobox__container .tabs--vertical {
	background: color("shades", "white");

	.tab .active {
		&::before {
			border-top: 3px solid $secondary-color;

			@include media-breakpoint-up(m) {
				border-left: 3px solid $secondary-color;
			}
		}

		.tab__title {
			color: color("grey", "darken-2") !important;
		}

		.btn-large {
			background: $secondary-color;
		}
	}
}

// cookie notice link color
.cookie-notice {
	a {
		color: #ffed00 !important;
	}

	.btn {
		color: #5A5A5A !important;
	}
}

// Photoswipe
.pswp__counter,
.pswp__top-bar .pswp__button {
	color: color("grey", "darken-2")
}

// nav desktop styles
.nav .nav-desktop {
	&__lower {
		&__left {
			@include media-breakpoint-up(xl) {
				@include flex(row, center, space-between, nowrap);
				min-width: 80%;
			}
		}
	}
}

.pagination li.active a {
	color: color("grey", "darken-2")
}

.webradio--header {
	display: flex;
}

// Gray weather icons
.icon-stack .icon,
.infobox__content .icon-stack .icon {
	color: inherit;
}

.infobox__headline h5 {
	color: color("grey", "darken-3");
}

.infobox__content {
	.infobox__content__button .btn {
		background: $secondary-color;
	}
}

.category__title {
	color: color("grey", "darken-3") !important;
}

// infobox 4th tab
.tabs--vertical .tab {
	padding: 0;
	height: auto !important;
}
.tabs--vertical .tab a {
    padding: .75rem 0 0 0;
}

// Social media links
.socials {
	img {
		display: block;
		width: auto;
		height: 55px;
		margin: 0;
	}
}
.breaker__headline__link {
	color: #FFFFFF !important;
}

// Quantyoo Login Sidenav
.sidenav .sidenav-login {
	background-color: color("shades", "white");

	&__link {
		color: $secondary-color !important;
		margin-right: rem(14px);

		@include media-breakpoint-down(xl) {
			margin-right: rem(10px)
		}
	}
}